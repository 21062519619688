import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import loadable from "@loadable/component"

import SEO from "../components/seo"
import { setLocation } from "../actions/setLocation"
import SuspenseLoading from "../components/SuspenseLoading"

const LandingPage = loadable(() =>
  import("../components/LandingPage/LandingPage")
)

// const LandingPage = lazy(() => import("../components/LandingPage/LandingPage"))

const IndexPage = () => {
  const dispatch = useDispatch()

  //Set the site location.
  useEffect(() => {
    dispatch(setLocation("/"))
  }, [dispatch])

  return (
    <>
      <SEO title="Home" />

      <LandingPage fallback={<SuspenseLoading />} />
    </>
  )
}

export default IndexPage
